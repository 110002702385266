import React, { useState, useEffect } from 'react';
import { useNavigate, useParams  } from "react-router-dom";
import { CCol, CRow } from '@coreui/react';
import CarMarketService from 'src/services/CarMarketService';

const moneyFormat = (value) => new Intl.NumberFormat().format(value);

const Contado = () => {
	const navigate = useNavigate();
  const [users, setUsers] = useState({});
  const [abonos, setAbonos] = useState(1);
  const [formData, setFormData] = useState({
    abonos: '',
    tipoSeparacion: '',
    responsabilidadGastos: '',
    precioVehiculo: '',
    clase: '',
    marca: '',
    tipo: '',
    modelo: '',
    color: '',
    placa: '',
    chasis: '',
    motor: '',
    linea: '',
    servicio: '',
    abono1: '',
    fechaAbono1: '',
    abono2: '',
    fechaAbono2: '',
    abono3: '',
    fechaAbono3: '',
    fechaContrato: ''
  });
  var { id } = useParams();

  

  const handleChange = (e) => {
    console.log(e.target.name)
    if(e.target.name === 'abonos'){      
      setAbonos(e.target.value)
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const inputs = Array.from({ length: abonos }, (_, index) => (
    <CRow className="mb-3">
      <CCol xs={6}>
        <div className="form-group">
          <label>Abono { index + 1}</label>
          <input
            type="text"
            name="abono1"
            value={formData.abono1}
            onChange={handleChange}
          />
        </div>
      </CCol>
      <CCol xs={6}>
        <div className="form-group">
          <label>Fecha Abono { index + 1}</label>
          <input
            type="date"
            name="fechaAbono1"
            value={formData.fechaAbono1}
            onChange={handleChange}
          />
        </div>
      </CCol>
      <CCol xs={12}>
        <input
            type="text"
            className="form-control"
            name="fechaAbono1"
            placeholder="Datos del banco"
            value={formData.fechaAbono1}
            onChange={handleChange}
          />
      </CCol>
    </CRow>
  ));
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data submitted:', formData);
  };

  const volver = () =>{
	  navigate('/car-market');
  }

  useEffect(() =>{
    CarMarketService.getAdmins().then(resp =>{
      setUsers(resp.data)
    })
  }, [null])
  useEffect(() => {
    CarMarketService.getById(id).then(data =>{
     let _data = data.data;
     if(_data.length > 0){
      _data = _data[0]
      let _car = {
        abonos: '',
        tipoSeparacion: '',
        responsabilidadGastos: '',
        precioVehiculo: moneyFormat(_data.price),
        clase: _data.vehicle_body_type,
        marca: _data.make,
        tipo: _data.vehicle_type,
        modelo: _data.model,
        color: _data.color,
        placa: _data.plate,
        chasis: '',
        motor: '',
        linea: _data.version,
        servicio: '',
        abono1: '',
        fechaAbono1: '',
        abono2: '',
        fechaAbono2: '',
        abono3: '',
        fechaAbono3: '',
        fechaContrato: ''
      }
      setFormData(_car)
     }
    })
  }, [null])

  return (
     <div className="contado">
     	 <form className="car-form" onSubmit={handleSubmit}>
      <h2 className="car-title">{formData.placa}</h2>

      <div className="form-row">
        <div className="form-group">
          <label>Nº de abonos</label>
          <input
            type="number"
            min={1}
            name="abonos"
            value={formData.abonos}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Tipo de separación</label>
          <select name="tipoSeparacion" value={formData.tipoSeparacion} onChange={handleChange}>
            <option value="">Seleccione</option>
            <option value="tipo1">Tipo 1</option>
            <option value="tipo2">Tipo 2</option>
          </select>
        </div>

        <div className="form-group">
          <label>Responsabilidad de gastos</label>
          <select name="responsabilidadGastos" value={formData.responsabilidadGastos} onChange={handleChange}>
            <option value="">Seleccione</option>
            {
                users.length > 0 ? users.map((item) => (
                  <option value={item.names} key={'make_'+item.id}>{ item.names }</option>
                )): ''
            }
          </select>
        </div>

        <div className="form-group">
          <label>Precio del Vehículo</label>
          <input
            type="text"
            name="precioVehiculo"
            value={formData.precioVehiculo}
            onChange={handleChange}
          />
        </div>
      </div>
      <CRow>
      	<CCol xs={3}>
      		<div className="form-group">
          <label>Clase</label>
          <input
            type="text"
            name="clase"
            value={formData.clase}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Marca</label>
          <input
            type="text"
            name="marca"
            value={formData.marca}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Tipo</label>
          <input
            type="text"
            name="tipo"
            value={formData.tipo}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Modelo</label>
          <input
            type="text"
            name="modelo"
            value={formData.modelo}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Color</label>
          <input
            type="text"
            name="color"
            value={formData.color}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Placa</label>
          <input
            type="text"
            name="placa"
            value={formData.placa}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Chasis</label>
          <input
            type="text"
            name="chasis"
            value={formData.chasis}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Motor</label>
          <input
            type="text"
            name="motor"
            value={formData.motor}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Línea</label>
          <input
            type="text"
            name="linea"
            value={formData.linea}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Servicio</label>
          <input
            type="text"
            name="servicio"
            value={formData.servicio}
            onChange={handleChange}
          />
        </div>
      	</CCol>
      	<CCol xs={9}>
          { inputs }
      		<CRow className="mb-3">
      			<CCol xs={12}>
      				<div className="form-group">
				        <label>Fecha de celebración de contrato</label>
				        <input
				          type="date"
				          name="fechaContrato"
				          value={formData.fechaContrato}
				          onChange={handleChange}
				        />
				      </div>
      			</CCol>
      		</CRow>
      
      	</CCol>
      </CRow>

      <div className="form-actions">
        <button type="submit" className="save-btn">Guardar</button>
        <button type="button" className="cancel-btn" onClick={ volver }>Cancelar</button>
      </div>
    </form>
     </div>
  );
};

export default Contado;
