import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from "react-router-dom";
import { CRow, CCol, CFormLabel } from '@coreui/react';
import VehiculosService from "src/services/VehiculosService";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Card } from './Card.js'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper';
import { Switch } from 'antd';

const moneyFormat = (value) => new Intl.NumberFormat().format(value);

const Edit = () =>{
    const [car, setCar] = useState({});
    var { id } = useParams();
    const [photos, setPhotos] = useState([]);
    const [isTriploV, setIsTriploV] = useState(false);
    const [isTriplo, setIsTriplo] = useState(false);
    const [statusV, setStatus] = useState(false);
    const [images, setImages] = useState([]);
    const [isHovered, setIsHovered] = useState(false);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setPhotos((prevCards) =>
          update(prevCards, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, prevCards[dragIndex]],
            ],
          }),
        )
    }, [])

    const renderCard = useCallback((card, index) => {
        return (
          <Card
            key={card.id}
            index={index}
            id={card.id}
            text={card.url}
            moveCard={moveCard}
          />
        )
    }, [])

    const validate = () => {
        // let validationErrors = {};
        // if (!carData.brand) validationErrors.brand = 'Marca es obligatoria';
        // if (!carData.year) validationErrors.year = 'Año es obligatorio';
        // if (!carData.model) validationErrors.model = 'Modelo es obligatorio';
        // if (!carData.linea) validationErrors.linea = 'Version es obligatorio';
        // if (!carData.plate) validationErrors.plate = 'Placa es obligatoria';

        // setErrors(validationErrors);
        // return Object.keys(validationErrors).length === 0; // Retorna true si no hay errores
    };

    const getCar = () =>{
        VehiculosService.get(id).then(resp =>{
            console.log(resp.response)
            setCar(resp.response);
            let photos = resp.response.pictures.split(',');
            let photosArray = [];
            photos.forEach((element, index) => {
                photosArray.push({ id: index + 1, url: element })
            });
            setPhotos(photosArray);
        }).catch(error => console.log(error));
    }

    const handleChange = (e) => {
        // setCarData({
        //     ...carData,
        //     [e.target.name]: e.target.value
        // });
    };

    const save = () =>{
        if (validate()) {
            // console.log(carData);
            // Aquí puedes continuar con la lógica de guardar
        }
    }
    const changeStatus = (checked) =>{
        // let data = {
        //     id: item.id_mercado_libre,
        //     value: checked ? 'active' : 'inactive'
        // }
        // VehiculosService.changeStatus(data).then(resp =>{
        //     setStatus(checked)
        // }).catch(error => console.log(error))
        
    }
    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        const newImages = files.map((file) => URL.createObjectURL(file));
        setImages([...images, ...newImages]);
    };

    useEffect(() => {
        getCar();
    }, [])

    return(
        <>
            <CRow>
                <CCol xs={12}>
                    <div className='d-flex justify-content-between'>
                        <h4>{car.title}</h4>
                        <div className='d-flex align-items-center'>
                            {
                                car.status && car.status == 'active' ? <span class="badge text-bg-success">Activo</span> : <span class="badge text-bg-danger">Cerrado</span>
                            } 
                            {
                                car.published && car.published == 1 ? <span class="badge text-bg-success ms-2">Publicado</span> : <span class="badge text-bg-danger ms-2">Sin publicar</span>
                            }     
                            <div class="progress ms-2" role="progressbar" aria-label="Example with label" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100">
                                <div class="progress-bar" style={{ width: '45%'}}>45%</div>
                            </div>                      
                        </div>
                    </div>
                    <hr />
                    <form>
                        <CRow>
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Marca</label>
                                    <input name="marca" value={car.make} placeholder="Marca" className='form-control' disabled />
                                </div>
                            </CCol>
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Año</label>
                                    <input name="year" value={car.year} placeholder="Año" className='form-control' disabled />
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Modelo</label>
                                    <input name="modelo" value={car.model} placeholder="Modelo" className='form-control' disabled />
                                </div>
                            </CCol> 
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Linea</label>
                                    <input name="version" value={car.version} placeholder="Linea" className='form-control' disabled />
                                </div>
                            </CCol>                         
                        </CRow>
                        <CRow>     
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Tipo de vehículo</label>
                                    <input name="version" value={car.vehicle_type} placeholder="Tipo" className='form-control' />
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Tipo de carroceria</label>
                                    <input name="version" value={car.vehicle_body_type} placeholder="Carroceria" className='form-control' />
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Transmisión</label>
                                    <input name="version" value={car.transmission} placeholder="Transmisión" className='form-control' />
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Tipo de combustible</label>
                                    <input name="version" value={car.fuel_type} placeholder="Combustible" className='form-control' />
                                </div>
                            </CCol>                           
                        </CRow>
                        <CRow> 
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Color</label>
                                    <input name="version" value={car.color} placeholder="Color" className='form-control' />
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Kilometraje</label>
                                    <input name="version" value={ moneyFormat(car.kilometers) } placeholder="KM" className='form-control' />
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Chasis</label>
                                    <input name="version" value={car.chasis} placeholder="Chasis" className='form-control' />
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Motor</label>
                                    <input name="version" value={car.motor} placeholder="Motor" className='form-control' />
                                </div>
                            </CCol>                           
                        </CRow>
                        <CRow>
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <CFormLabel>¿Vehiculo vendido?</CFormLabel>
                                    <div>
                                        <Switch checked={statusV} />
                                    </div>                    
                                </div>
                            </CCol>
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <CFormLabel>¿Vehiculo separado?</CFormLabel>
                                    <div>
                                        <Switch checked={statusV} />
                                    </div>                    
                                </div>
                            </CCol>
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <CFormLabel>¿Estado? inactivo/ activo</CFormLabel>
                                    <div>
                                        <Switch checked={statusV} onClick={ changeStatus } />
                                    </div>                    
                                </div>
                            </CCol>
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <CFormLabel>¿Vehículo de Triplo?</CFormLabel>
                                    <div>
                                        <Switch checked={isTriploV} onClick={ isTriplo } />
                                    </div>                    
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol xs={6}>
                                <div className='form-group'>
                                    <label>Precio</label>
                                    <input name="price" value={ moneyFormat(car.price)} placeholder="Precio" className='form-control' disabled />
                                </div>
                            </CCol>
                            <CCol xs={6}>
                                <div className='form-group'>
                                    <label>Placa</label>
                                    <input name="plate" placeholder="Placa" className='form-control' value={ car.plate } onChange={handleChange} />
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol xs={12}>
                                <div className='form-group'>
                                    <label>Descripción</label>
                                    <ReactQuill value={ car.description } />
                                </div>
                            </CCol>
                        </CRow>
                    </form>                    
                </CCol>
                <CCol xs={12}>
                    <div class="mb-3">                        
                        <label for="formFile" class="form-label">Selecciona la(s) foto(s)</label>
                        <input class="form-control" type="file" id="formFile" multiple onChange={handleImageUpload} />
                        <span class="badge text-bg-warning mt-3">Recuerde que la primera imagen del listado es la que corresponde a la portada.</span>
                    </div>
                    {
                        photos.length > 0 ?
                        <DndProvider backend={HTML5Backend}>
                            <CRow>
                            {
                                photos.map((card, i) => renderCard(card, i))
                            }
                            </CRow>   
                        </DndProvider> : ''
                    }
                </CCol>
            </CRow>
        </>
    )
}

export default Edit;