import React ,{ useEffect, useState } from 'react'
import { Col, Row, Modal } from 'antd';
import ClientesService from 'src/services/Clientes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tag } from 'primereact/tag';
import DataTable from 'react-data-table-component';
import { EyeOutlined} from '@ant-design/icons';
import { CRow } from '@coreui/react';

const Index = () => {
  const [data, setData] = useState([])
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [item, setItem] = useState({});
  const [filters, setFilters] = useState({
      "search" : ''
  })

  useEffect(() => {
    getData(page, limit, skip);
  }, [])

  const columns = [
    {
      name: 'Nombres',
      selector: rowData => rowData.names,
      sortable: true,
      width: '300px'
    },    
    {
      name: 'Identificación',
      selector: row => (row.identification_type ? row.identification_type : '' )+" "+(row.identification ? row.identification : ''),
      sortable: true,
      width: '140px'
    },
    {
      name: 'Origen',
      selector: rowData => <span className={ rowData.source === 'ventas' ? "badge rounded-pill text-bg-info" : "badge rounded-pill bg-warning text-dark"}>{ rowData.source }</span>,
      sortable: true,
      width: '100px'
    },
    {
        name: 'Correo',
        selector: row => row.email,
        sortable: true,
        width: '300px'
    }, 
    {
      name: 'Dirección',
      selector: row => row.address,
      sortable: true,
      width: '300px'
    },      
    {
      name: 'Teléfono',
      selector: row => row.phone,
      sortable: true,
      width: '120px'
    }, 
    {
      name: '¿Suscrito?',
      selector: rowData => <Tag value={rowData.unsuscribe === 0 ? 'Si' : 'No'} severity={getSeverity(rowData)}></Tag>,
      sortable: true,
      width: '110px'
    },      
    {
      name: 'Registrado el',
      selector: row => row.registred,
      sortable: true,
    },
    {
      name: 'Acciones',
      selector: rowData => {
        return <div className='d-flex justify-content-between'>
            <div className='text-center cc1' onClick={ () => showData(rowData) }>
                <EyeOutlined className='iconAprobar' title='Ver'/>
            </div>
        </div>           
      }
    }
];

  const showData = (data) =>{
    setItem(data)
    setIsModalOpen(true);
  } 

  const getData = (ppage) =>{
    setLoading(true);
    let _filter = '';
    if(filters.search !== '') _filter += '&search='+filters.search;
    ClientesService.getAll(ppage,limit,skip,_filter).then(resp =>{
      let _data = resp.data;
      setTotalRows(resp.total);
      setData(_data);
      setLoading(false);
    })
    .catch(error => console.log(error));
  }

  const handlePageChange = page => {
    getData(page);
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {  
      setLoading(true)
      setLimit(newPerPage)
      getData(page);    
      setLoading(false)
  };

  const saveField = (event) =>{
      let _filters = filters;
      switch (event.target.name) {
        case 'search':
          filters.search = event.target.value
          break;
        default:
          break;
      }
      setFilters(_filters)
      getData(1)
  }

  const getSeverity = (rowData) => {
    switch (rowData.unsuscribe) {
        case 1:
            return 'warning';

        case 0:
            return 'success';

        default:
            return null;
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };


  return(
    <>
    <Modal title="Ver cliente" open={isModalOpen} cancelText="Cerrar" onCancel={handleOk} onOk={handleOk} okText={"Aceptar"}>
        <ul>
          <li>Nombres: { item.names }</li>
          <li>Identificación: { (item.identification_type ? item.identification_type : '' )+" "+(item.identification ? item.identification : '') }</li>
          <li>Origen: <span className={ item.source === 'ventas' ? "badge rounded-pill text-bg-info" : "badge rounded-pill bg-warning text-dark"}>{ item.source }</span></li>
          <li>Correo: <a target='_blank' href={"mail:"+item.email}>{ item.email }</a></li>
          <li>Dirección: { item.address }</li>
          <li>Teléfono: <a target='_blank' href={"cel:"+item.phone}>{ item.phone }</a></li>
          <li>¿Está suscrito?: { <Tag value={item.unsuscribe === 0 ? 'Si' : 'No'} severity={getSeverity(item)}></Tag> }</li>
          <li>Fecha de registro: { item.registred }</li>
        </ul>
    </Modal>
    <Row>
      <ToastContainer />
      <Col span={24} className='text-center my-4'>
        <h1>Listado de clientes</h1>
      </Col>
    </Row>
    <CRow className="mb-2">
      <Col xs={24} md={8} offset={16}>
        <span className="p-input-icon-left w-100">
          <i className="pi pi-search" />
          <input type="search" className='form-control' name="search" defaultValue={ filters.search } onChange={saveField} placeholder="Buscar..." />
        </span>
      </Col>
    </CRow>
    <Row>
      <Col span={24}>
        <DataTable
            noDataComponent={"No se han encontrado resultados"}
            columns={ columns }
            data={data}
            progressPending={loading}
            pagination 
            paginationServer
            responsive             
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
        />
      </Col>
    </Row>
    </>
  )
}

export default Index;
