import React, { useState, useEffect } from 'react';
import './Styles.scss'; // Importa el archivo de estilos
import { useNavigate } from "react-router-dom";
import { CCol, CRow } from '@coreui/react';
import CarMarketService from 'src/services/CarMarketService';

function IndexCont() {
  const navigate = useNavigate();

  // Estado para los inputs
  const [plate, setPlate] = useState('');
  const [buyerId, setBuyerId] = useState('');
  const [sellerId, setSellerId] = useState('');

  // Estado para controlar si los botones están habilitados
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // Función para manejar la navegación
  const contado = () => {
    let _filter = 'search='+plate;
    CarMarketService.getAll(_filter).then(resp =>{
        let _data = resp.data;
        if(_data.length > 0){
          let id = _data[0].id
          navigate('/car-market/contado/'+id);
        }
    })
  };

  // Efecto para verificar si los campos están completos y habilitar botones
  useEffect(() => {
    //const isPlateValid = plate.length === 6 && /^[A-Z0-9]+$/.test(plate);
    if (plate || buyerId || sellerId) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [plate, buyerId, sellerId]);

  // Funciones para manejar el cambio de valor de los inputs
  const handlePlateChange = (e) => {
    setPlate(e.target.value.toUpperCase()); // Convertir siempre a mayúsculas
  };

  const handleBuyerIdChange = (e) => {
    setBuyerId(e.target.value);
  };

  const handleSellerIdChange = (e) => {
    setSellerId(e.target.value);
  };

  return (
    <CRow>
      <CCol xs={12} className="text-center">
        <div className="text-center app-container">
          <div className="input-container">
            <input
              type="text"
              placeholder="Placa del vehículo"
              className="input-field text-uppercase"
              value={plate}
              onChange={handlePlateChange}
              maxLength={6} // Limitar a 6 caracteres
            />
            <input
              type="text"
              placeholder="N° de identificación del comprador"
              className="input-field"
              value={buyerId}
              onChange={handleBuyerIdChange}
            />
            <input
              type="text"
              placeholder="N° de identificación del vendedor"
              className="input-field"
              value={sellerId}
              onChange={handleSellerIdChange}
            />
          </div>          
        </div>
      </CCol>
      <CCol xs={12}>
        <div className="text-center app-container">
          <div className="button-container">
            <button
              className="custom-button"
              onClick={contado}
              disabled={isButtonDisabled} 
            >
              Contado
            </button>
            <button className="custom-button" disabled={isButtonDisabled}>Contado y Credito</button>
            <button className="custom-button" disabled={isButtonDisabled}>Credito</button>
            <button className="custom-button" disabled={isButtonDisabled}>Carro parte de pago</button>
          </div>
        </div>
      </CCol>
    </CRow>
  );
}

export default IndexCont;