import API from "./Config";

const CarMarketService = {
    getAll: (filter) => new Promise((resolve, reject) => {
        API.get("/car-market/search?"+filter)
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
    getById: (id) => new Promise((resolve, reject) => {
        API.get("/car-market/"+id)
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
    getAdmins: () => new Promise((resolve, reject) => {
      API.get("/admins/")
      .then(
          res => res.data
      )
        .then(
          data => resolve(data)
        )
        .catch(
          err => reject(err)
        )
  })
}

export default CarMarketService;
