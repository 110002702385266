import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TripipeService from 'src/services/TripipeService';
import ActasService from 'src/services/ActasService';
import { Col, Modal, Form, message, Input, Collapse, Select, Upload, Button } from 'antd';
import { Link  } from "react-router-dom";
import $ from "jquery";
import { useAuthUser } from 'react-auth-kit';
import Moment from 'moment'
import { CRow } from '@coreui/react';
import { UploadOutlined } from '@ant-design/icons'
import DataTable from 'react-data-table-component'
const moneyFormat = (value) => "COP "+ new Intl.NumberFormat().format(value);

const Index = () => {
    const [data, setData] = useState(null);
    const [columnSelected, setColumnSelected] = useState({title: ''});
    const [taskSelected, setTaskSelected] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalLabelOpen, setIsModalLabelOpen] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [isLoading, setIsLoading] = useState(true); 
    const [isEdit, setIsEdit] = useState(false); 
    const [notes, setNotes] = useState([]); 
    const [labels, setLabels] = useState([]); 
    const [users, setUsers] = useState([]); 
    const [dataLabels, setDataLabels] = useState([]);
    const authUser = useAuthUser();
    const [form_create] = Form.useForm();
    const [flabel] = Form.useForm();
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [showResults, setShowResults] = useState(false);
    
    const columns = [
        {
            name: 'Nombre',
            selector: rowData => rowData.name
        }, 
        {
            name: 'Color',
            selector: rowData => rowData.color
        },
        {
            name: 'Acciones',
            selector: rowData => {
                return <div className='d-flex justify-content-between'>
                    {/* <Link onClick={ () => editarLabel(rowData) }>
                        Editar
                    </Link> */}
                    &nbsp;&nbsp;
                    <Link onClick={ () => eliminarLabel(rowData) }>
                        Eliminar
                    </Link> 
                </div>
            }
        }
    ]
    const eliminarLabel = (_label) =>{
        TripipeService.deleteLabel({id: _label.id}).then(resp =>{
            getLabels();
        })
    }

    const showModalEtiquetas = () =>{
        setIsModalLabelOpen(true)
    }

    const handleEtiCancel = () =>{
        setIsModalLabelOpen(false)
    }

    const showModal = (task) =>{        
        setTaskSelected(task)
        setTitleModal("Editar Deal");
        setIsEdit(true)
        $("[name=notas_add]").val('')
        $("[name=inputSearch]").val('')
        setResults([])
        let id = task.id.split("_");
        TripipeService.getTAsk(id[1]).then(resp =>{
            setNotes(resp.result.notes)
            setLabels(resp.result.labels)
            let _task = resp.result.task[0]
            let _fields = {
                marca: _task.marca,
                modelo: _task.modelo,
                linea: _task.linea,
                placa: _task.placa,
                costo: _task.cost,
                name_contact: _task.name_contact,
                email_contact: _task.email_contact
            }
            form_create.setFieldsValue(_fields)
        })
        setIsModalOpen(true)
    }

    const handleOk = () => {
        form_create
        .validateFields()
        .then((values) => {            
            let data = {
                placa: values.placa,
                marca: values.marca,
                modelo: values.modelo,
                linea: values.linea,
                costo: values.costo,
                user_id: authUser().id,
                column_id: columnSelected.id.split("-")[1],
                name_contact: values.name_contact,
                email_contact: values.email_contact
            }
            if(!isEdit){
                TripipeService.addNewTask(data).then(resp =>{
                    setIsEdit(true)
                    //getData()
                    message.success("Guardado exitosamente");
                })
            }else{
                //update
            }
        })
        .catch((info) => {
            console.log("Validate Failed:", info);
        });
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onDragEnd = (result) =>{
        const { destination, source, draggableId } = result;
        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const startColumn = data.columns[source.droppableId];
        const finishColumn = data.columns[destination.droppableId];

        if (startColumn === finishColumn) {
            const newTaskIds = Array.from(startColumn.taskIds);
            newTaskIds.splice(source.index, 1);
            newTaskIds.splice(destination.index, 0, draggableId);

            const newColumn = {
                ...startColumn,
                taskIds: newTaskIds,
            };

            const newData = {
                ...data,
                columns: {
                  ...data.columns,
                  [newColumn.id]: newColumn,
                },
            };
        
            setData(newData);
        } else {
            const startTaskIds = Array.from(startColumn.taskIds);
            startTaskIds.splice(source.index, 1);
            const newStartColumn = {
              ...startColumn,
              taskIds: startTaskIds,
            };
      
            const finishTaskIds = Array.from(finishColumn.taskIds);
            finishTaskIds.splice(destination.index, 0, draggableId);
            const newFinishColumn = {
              ...finishColumn,
              taskIds: finishTaskIds,
            };
      
            const newData = {
              ...data,
              columns: {
                ...data.columns,
                [newStartColumn.id]: newStartColumn,
                [newFinishColumn.id]: newFinishColumn,
              },
            };
      
            setData(newData);
        }
        let column = destination.droppableId.split("-")[1];
        let task = draggableId.split("_")[1];
        let _data = {
            'column_id': column,
            'task_id': task
        }
        TripipeService.updateDealsPosicion(_data).then(resp => console.log(resp))
    }
    
    const addTask = (_column) =>{
        setIsEdit(false)
        setColumnSelected(_column)
        setTitleModal("Nuevo Deal en "+_column.title);
        setIsModalOpen(true);
    }

    const addNote = () =>{
        if($("[name=notas_add]").val() !== '' && $("[name=notas_add]").val().length > 4){
            let _nota = {
                note: $("[name=notas_add]").val(),
                user_id: authUser().id,
                tri_deal_id: taskSelected.id.split("_")[1]
            }
            TripipeService.addNewNote(_nota).then(resp =>{
                message.success("Nota agregada con éxito");
                TripipeService.getTAsk(taskSelected.id.split("_")[1]).then(resp =>{
                    setNotes(resp.result.notes)
                    $("[name=notas_add]").val()
                })
            })
        }else{
            message.error("No se puede crear una nota vacia o de menos de 4 caracteres");
        }
    }

    const searchPlate = (event) =>{
        if(event.target.id === 'basic_placa' && event.target.value.length === 6){
            ActasService.getByPlate(event.target.value).then(resp =>{
                let _data = resp.data
                if(_data.length > 0){
                    _data = _data[0]
                    let _fields = {
                        marca: _data.make,
                        modelo: _data.model,
                        linea: _data.version
                    }
                    form_create.setFieldsValue(_fields)
                }
            })
        }
    }

    const getUsers = () =>{
        TripipeService.getUsers().then(resp =>{
            console.log(resp)
            setUsers(resp.users)
        })
    }

    const getLabels = () =>{
        TripipeService.getLabels().then(resp =>{
            setDataLabels(resp.labels)
        })
    }

    const onSaveLabel = () =>{
        flabel
        .validateFields()
        .then((values) => {            
            let _data = {
                name: values.nombre,
                color: values.color
            }
            TripipeService.addLabel(_data).then(resp =>{
                flabel.resetFields();
                getLabels()
            })
        })
        .catch((info) => {
            console.log("Validate Failed:", info);
        });
    }

    const search = (event) =>{
        if (event.target.value) {
            TripipeService.searchAll(event.target.value).then(resp =>{
                setResults(resp.results)
                setShowResults(true);
            })
        } else {
            setResults([]);
            setShowResults(false);
        }
    }

    const handleBlur = () => {
        setTimeout(() => setShowResults(false), 100); // Añade un pequeño retraso antes de cerrar el menú para permitir hacer clic en los resultados
    };

    const handleFocus = () => {
        if (results.length > 0) {
          setShowResults(true);
        }
    };

    useEffect(() => {
        const getData = () => {
            TripipeService.get().then(resp => {
              setData(resp.result);  // Guarda los datos obtenidos
              setIsLoading(false);  // Cambia el estado de carga a false
            }).catch(error => {
              console.error('Error al obtener los datos:', error);
              setIsLoading(false);  // Cambia el estado de carga a false incluso si hay un error
            });
        };
      
        getData();
        getUsers();
        getLabels();
    },[])

    if (isLoading) {
        return <div>Cargando...</div>;
    }

    return(
        <div>
            <Modal className='ctri-modal' title="Etiquetas" width={500} open={isModalLabelOpen} cancelText="Cerrar" onOk={handleEtiCancel} onCancel={handleEtiCancel}>
                <CRow>
                    <Col span={24} className='text-end'>
                        <Form name="flabel" form={flabel}  layout='vertical'>
                            <CRow>
                                <Col span={12}>
                                    <Form.Item label="Nombre" name="nombre"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Este campo es requerido!',
                                            }
                                        ]}>
                                        <Input type='text' placeholder='Ej: Nuevos' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Color" name="color"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Este campo es requerido!',
                                            }
                                        ]}>
                                        <Input type='text' placeholder='Ej: #FFFFFF' />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Button type="primary" onClick={onSaveLabel}>Guardar</Button>
                                </Col>
                            </CRow>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <DataTable
                            noDataComponent={"No se han encontrado resultados"}
                            columns={ columns }
                            data={dataLabels}
                            pagination 
                            paginationServer
                            responsive             
                        />
                    </Col>
                </CRow>
            </Modal>
            <Modal className='ctri-modal' title={ titleModal } width={700} open={isModalOpen} okText="Guardar" cancelText="Cancelar" onOk={handleOk} onCancel={handleCancel}>
                <CRow className='mb-4'>
                    <Col span={24} className='mb-2'>
                        {labels.map((item, index) => (
                            <span key={`label-${index}`} style={{ background: item.color, color: '#FFFFFF', padding: '1px 16px'}} className='me-1'>
                                { item.name }
                            </span>         
                        ))}  
                    </Col>
                    <Col span={16}>
                        <Form name="basic" form={form_create} layout='vertical' onChange={ searchPlate }>
                            <CRow> 
                                <Col md={12} xs={24}>
                                    <Form.Item label="Placa" name="placa"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Este campo es requerido!',
                                            }
                                        ]}>
                                        <Input type='text' placeholder='Ej: RNR549' />
                                    </Form.Item>
                                </Col> 
                                <Col md={12} xs={24}>
                                    <Form.Item label="Marca" name="marca"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Este campo es requerido!',
                                            }
                                        ]}>
                                        <Input type='text' placeholder='Ej: Mercedes-Benz'/>
                                    </Form.Item>
                                </Col> 
                                <Col md={12} xs={24}>
                                    <Form.Item label="Modelo" name="modelo"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Este campo es requerido!',
                                            }
                                        ]}>
                                        <Input type='text' placeholder='Ej: Clase SLK'/>
                                    </Form.Item>
                                </Col> 
                                <Col md={12} xs={24}>
                                    <Form.Item label="Linea" name="linea"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Este campo es requerido!',
                                            }
                                        ]}>
                                        <Input type='text' placeholder='Ej: SLK 200'/>
                                    </Form.Item>
                                </Col>
                                <Col md={24} xs={24}>
                                    <Form.Item label="Costo" name="costo">
                                        <Input type='text' placeholder='Ej: 50000000'/>
                                    </Form.Item>
                                </Col>  
                                <Col md={12} xs={24}>
                                    <Form.Item label="Nombre" name="name_contact"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Este campo es requerido!',
                                            }
                                        ]}>
                                        <Input type='text' placeholder='Nombre de contacto'/>
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24}>
                                    <Form.Item label="Correo" name="email_contact">
                                        <Input type='text' placeholder='Correo de contacto'/>
                                    </Form.Item>
                                </Col>         
                            </CRow>   
                        </Form>                     
                    </Col>                    
                    <Col span={8}>
                        <CRow>
                            {/* <Collapse accordion items={items} /> */}
                            <Col span={24}>
                                <label>Miembros</label>
                                <Select
                                    mode="multiple"
                                    style={{
                                        width: '100%',
                                    }}
                                    options={users}
                                />
                            </Col>
                            <Col span={24}>
                                <label>Etiquetas</label>
                                    <Select
                                    // onChange={ selectLabel }
                                    mode="multiple"
                                    style={{
                                    width: '100%',
                                    }}
                                    options={dataLabels}
                                />
                            </Col>
                        </CRow>
                    </Col>
                </CRow>
                <CRow>
                    <Col span={24} className='mb-2'>
                        <p className='lbl-modal mb-2'><strong>Notas</strong></p>
                        <textarea className='form-control' name='notas_add' placeholder='Notas'></textarea>
                        <button style={{ float: 'right', marginTop: '4px'}} type='button' className='btn btn-sm btn-primary' onClick={ addNote }>Agregar</button>
                    </Col>
                    <Col span={24}>                           
                        {notes.map((item, index) => (
                            <dl key={`note-${index}`} className='mb-0 border-bottom'>
                                <dt className='mb-1'><b>{item.names}: </b> <span style={{ float: 'right', fontSize: '12px'}}>{ Moment( item.created_at).format("DD/MM/YYYY h:mm:ss a")}</span></dt>
                                <dd>{ item.note}</dd>
                            </dl>         
                        ))}  
                    </Col>
                </CRow>
            </Modal>
            <CRow className='text-end mb-2'>
                <Col span={24}>
                    <button className='btn btn-info' onClick={ showModalEtiquetas }>Etiquetas</button>
                </Col>
            </CRow>
            <CRow className='mb-2 btripipe'>
                <Col span={7}></Col>
                <Col span={10}>
                    <div className='text-start'>
                        <input
                            type="text"
                            placeholder="Buscar..."
                            onChange={search}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            name="inputSearch"
                        />
                        {showResults && (
                            <div className="results-dropdown">
                            <ul className='results-list'>
                                {results.map((item, index) => (
                                <li key={index} onClick={() => showModal(item)} className="result-item">
                                    <div className="result-header">
                                        <span className="brand">{ item.title } { item.placa ? ' - '+item.placa : ''}</span>
                                    </div>
                                    <div className="result-price">
                                        { moneyFormat(item.cost) }
                                    </div>
                                        <div className="result-details">
                                        <p className="notes">
                                            <strong>NOTE:</strong> { item.nota }
                                        </p>
                                        { item.cant_nota > 1 ? <p className="notes"> Tiene mas notas (<strong>{ item.cant_nota}</strong>) </p> : '' }
                                        <p className="contact">
                                            { item.name_contact} { ' - '+item.columna}
                                        </p>
                                    </div>
                                </li>
                                ))}
                            </ul>
                        </div>
                        )}
                    </div>
                </Col>
            </CRow>
            <div className='ctripipe'>
                <CRow>
                    <Col span={24}>
                        <DragDropContext onDragEnd={onDragEnd}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {   
                                data && data.columns && data.columnOrder.map((columnId) => {
                                const column = data.columns[columnId];
                                const tasks = column.taskIds.map((taskId) => data.tasks[taskId]);
                                const totalCosto = tasks.reduce((acumulador, objeto) => acumulador + objeto.cost, 0);

                                return (
                                    column ? 
                                    <Droppable droppableId={column.id} key={column.id}>
                                        {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            className='column'                                    
                                        >
                                            <div className='d-flex justify-content-between'>
                                                <h3>{column.title}</h3>                                        
                                                <button className='btn btn_add' onClick={() => addTask(column)}>
                                                    +
                                                </button>
                                            </div>
                                            <p className='desp'>{ moneyFormat(totalCosto) } - { tasks.length } deals</p>
                                            {tasks && tasks.map((task, index) => (
                                                task.id ? 
                                                <Draggable key={task.id} draggableId={task.id} index={index} >
                                                    {(provided) => (
                                                    <div onClick={() => showModal(task) }
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps} className='task'>
                                                        <div>
                                                            <h6 className='mb-0'>{ task.content }</h6>  
                                                            <p className='contact'>{ task.contact ? task.contact : '-' }</p>                                          
                                                            <p className='mb-0'>{ moneyFormat(task.cost ? task.cost : 0) }</p>                                          
                                                        </div>
                                                    </div>
                                                    )}
                                                </Draggable> : null
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                        )}
                                    </Droppable> : null
                                    )
                                })
                            }
                        </div>
                    </DragDropContext>
                    </Col>
                </CRow>  
            </div>          
        </div>
    )
}

export default Index;