import { CCol, CRow } from '@coreui/react';
import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MakeService from 'src/services/MakeService';
import ModelService from 'src/services/ModelService';
import YearService from 'src/services/YearService';
import VersionService from 'src/services/VersionService';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const CreateCar = () =>{
    const [images, setImages] = useState([]);
    const [carData, setCarData] = useState({
        brand: '',
        model: '',
        year: '',
        linea: '',
        price: '',
        plate: '',
        description: ''
    });
    const [makes, setMakes] = useState([]);
    const [years, setYears] = useState([]);
    const [models, setModels] = useState([]);
    const [versions, setVersions] = useState([]);
    const [errors, setErrors] = useState({});

    const validate = () => {
        let validationErrors = {};
        if (!carData.brand) validationErrors.brand = 'Marca es obligatoria';
        if (!carData.year) validationErrors.year = 'Año es obligatorio';
        if (!carData.model) validationErrors.model = 'Modelo es obligatorio';
        if (!carData.linea) validationErrors.linea = 'Version es obligatorio';
        if (!carData.plate) validationErrors.plate = 'Placa es obligatoria';

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0; // Retorna true si no hay errores
    };

    const getMakes = () =>{
        MakeService.getAll()
        .then(data => {
            setMakes(data.data);
        })
        .catch( error => console.log(error));
    }

    const getYears = (event) =>{
        if(event.target.value !== ""){  
            YearService.getYearByMake(event.target.value)
            .then(data => {
                setYears(data.data);
            })
            .catch( error => console.log(error));
        }
    }

    const getModels = (event) =>{
        if(event.target.value !== ""){
            ModelService.getModelsByYear(event.target.value)
            .then(data => {
                setModels(data.data);
            })
            .catch( error => console.log(error));
        }
    }

    const getVersions = (event) =>{
        if(event.target.value !== ""){
            VersionService.getVersionsByModel(event.target.value)
            .then(data => {
                setVersions(data.data);
            })
            .catch( error => console.log(error));
        }
    }

    const handleChange = (e) => {
        if(e.target.name == 'brand'){
            getYears(e)
        }
        if(e.target.name == 'year'){
            getModels(e)
        }
        if(e.target.name == 'model'){
            getVersions(e)
        }

        setCarData({
            ...carData,
            [e.target.name]: e.target.value
        });
    };

    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        const newImages = files.map((file) => URL.createObjectURL(file));
        setImages([...images, ...newImages]);
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedImages = reorder(
        images,
        result.source.index,
        result.destination.index
        );
        setImages(reorderedImages);
    };

    const handleDeleteImage = (index) => {
        setImages(images.filter((_, i) => i !== index));
    };

    const handleDescriptionChange = (value) => {
        setCarData({ ...carData, description: value });
    };

    const save = () =>{
        if (validate()) {
            console.log(carData);
            // Aquí puedes continuar con la lógica de guardar
        }
    }
    useEffect(() => {
        getMakes();
    }, [])

    return(
        <>
            <CRow>
                <CCol xs={8}>
                    <h3>Información del vehículo</h3>
                    <hr />
                    <form>
                        <CRow>
                            <CCol xs={6}>
                                <div className='form-group'>
                                    <label>Marca</label>
                                    <select className="form-select" name="brand" onChange={handleChange}>
                                        <option value="">Seleccione</option>
                                        {
                                            makes.length > 0 ? makes.map(
                                            (item, index) => (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            )
                                            ): ''
                                        }
                                    </select>
                                    {errors.brand && <span className="text-danger fs-6">{errors.brand}</span>}
                                </div>
                            </CCol>
                            <CCol xs={6}>
                                <div className='form-group'>
                                    <label>Año</label>
                                    <select className='form-select' name="year" onChange={handleChange}>
                                        <option value="">Seleccione</option>
                                        {
                                            years.length > 0 ? years.map(
                                            (item, index) => (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            )
                                            ): ''
                                        }
                                    </select>
                                    {errors.year && <span className="text-danger fs-6">{errors.year}</span>}
                                </div>
                            </CCol>                            
                        </CRow>
                        <CRow>
                            <CCol xs={6}>
                                <div className='form-group'>
                                    <label>Modelo</label>
                                    <select className='form-select' name="model" onChange={handleChange}>
                                        <option value="">Seleccione</option>
                                        {
                                            models.length > 0 ? models.map(
                                            (item, index) => (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            )
                                            ): ''
                                        }
                                    </select>
                                     {errors.model && <span className="text-danger fs-6">{errors.model}</span>}
                                </div>
                            </CCol>
                            <CCol xs={6}>
                                <div className='form-group'>
                                    <label>Linea</label>
                                    <select className='form-select' name="linea" onChange={handleChange}>
                                        <option value="">Seleccione</option>
                                        {
                                            versions.length > 0 ? versions.map(
                                            (item, index) => (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            )
                                            ): ''
                                        }
                                    </select>
                                     {errors.linea && <span className="text-danger fs-6">{errors.linea}</span>}
                                </div>
                            </CCol>                            
                        </CRow>
                        <CRow>
                            <CCol xs={6}>
                                <div className='form-group'>
                                    <label>Precio</label>
                                    <input name="price" placeholder="Precio" className='form-control' onChange={handleChange} />
                                </div>
                            </CCol>
                            <CCol xs={6}>
                                <div className='form-group'>
                                    <label>Placa</label>
                                    <input name="plate" placeholder="Placa" className='form-control' onChange={handleChange} />
                                    {errors.plate && <span className="text-danger fs-6">{errors.plate}</span>}
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol xs={12}>
                                <div className='form-group'>
                                    <label>Descripción</label>
                                    <ReactQuill value={carData.description} onChange={handleDescriptionChange} />
                                </div>
                            </CCol>
                        </CRow>
                    </form>                    
                </CCol>
                <CCol xs={4}>
                    <h3>Fotos del vehículo</h3>
                    <hr />
                    <CRow>
                        <CCol xs={12}>
                            <div class="mb-3">
                                <label for="formFile" class="form-label">Selecciona la(s) foto(s)</label>
                                <input class="form-control" type="file" id="formFile" multiple onChange={handleImageUpload} />
                            </div>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="images" direction="vertical">
                                {(provided) => (
                                    <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                                        gap: '16px',
                                        marginTop: '16px'
                                    }}
                                    >
                                    {images.map((img, index) => (
                                        <Draggable key={img} draggableId={img} index={index}>
                                        {(provided) => (
                                            <ImageWithDeleteButton
                                            provided={provided}
                                            img={img}
                                            index={index}
                                            handleDeleteImage={handleDeleteImage}
                                            />
                                        )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>
                            </DragDropContext>
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
            <CRow>
                <CCol xs={24}>
                    <button className="btn btn-primary" onClick={ save }>Guardar</button>
                </CCol>
            </CRow>
        </>
    )
}

const ImageWithDeleteButton = ({ provided, img, index, handleDeleteImage }) => {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{
          ...provided.draggableProps.style,
          cursor: 'move',
          position: 'relative',
          textAlign: 'center',
          background: '#f0f0f0',
          padding: '8px',
          borderRadius: '8px'
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img
          src={img}
          alt={`Vehículo ${index + 1}`}
          style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
        />
        {isHovered && (
          <button
            onClick={() => handleDeleteImage(index)}
            style={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              background: 'red',
              color: 'white',
              border: 'none',
              borderRadius: '50%',
              padding: '4px 8px',
              cursor: 'pointer'
            }}
          >
            X
          </button>
        )}
      </div>
    );
};

  
export default CreateCar;